* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  
  body {
    font-family: 'Work Sans', sans-serif;
  }
  .font-12{
    font-size: 12px;
  }
  .font-13{
    font-size: 13px;
  }
  .font-14{
    font-size: 14px;
  }
  .font-15{
    font-size: 15px;
  }
  .select {
    font-weight: 300;
    font-size: 15px;
  }
  .pry-color{
    color: #3366FB;
  }
  .font-10{
    font-size: 10px;
  }
  .text-dark{
    color: #091C7A !important;
  }
  .text-danger{
    color: #dc3545 !important;
  }
  .text-light{
    color: #C7CFE8 !important;
  }
  .text-grey{
    color: #9EA8BE
  }
  .pointer{
    cursor: pointer;
  }
  .no-pointer{
    cursor: not-allowed;
  }
  .btn{
    font-size: 14px;
    padding: 5px 25px;
    border: none;
    border-radius: .25rem;
  }
  .btn-pry, .btn-pry:hover, .btn-pry:focus{
    background-color: #0095FF;
  }
  .btn-sec, .btn-sec:hover, .btn-sec:focus{
    background-color: #EDF1F7;
    color: #091C7A;
    font-weight: 600;
  }
  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  a{
    text-decoration: none;
  }
  .text-right{
    text-align: right;
  }
    .text-left{
    text-align: left;
  }
  .text-underline{
    text-decoration: underline;
  }
.border-left{
  border-left: 1px solid  #dee2e6;
}
.border-right{
  border-right: 1px solid  #dee2e6;
}
  textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ffffff;
}


.react-switch-bg{
  height: 24px !important;
  width: 51px !important;
  /* background: #C5CEE0 !important; */
}
.react-switch-handle{
  height: 22px !important;
  width: 21px !important;
  /* transform: translateX(32px) !important; */

}

::-webkit-scrollbar-track {
  margin-top: 1px;
  margin-bottom: 1px;
 
}