.add-post-modal {
    .textarea{
        min-height: 10rem;
    }
  .text-desc-wrap,
  .milestone-desc-wrap {
    min-height: 22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3rem;
    color: #1995fc;
    font-weight: 800;
    .desc {
      text-align: center;
    }
  }
  .milestone-desc-wrap {
    flex-direction: column;
    color: #222B45;
    .count{
    font-size: 4rem;
    font-weight: 600;
}

    .star {
      font-size: 3rem;
      color: #1995fc;
    }
    .desc {
      padding: 0 5rem;
    }
  }
}
