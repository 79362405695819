.auth{
    min-height: 100vh;
    background: url(../assets/img/background.jpg);
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    overflow: hidden;
.auth-row{
    min-height: 100vh;
}
    .form-wrap{
        padding: 0 1rem;
    }
    .auth__logo{
        width: 18rem;
    }
    .btnAuth__submit{
        font-size: 13px;
        font-weight: 500;
    }
    .form-group{
        position: relative;
    }
    .right-input__icon{
    position: absolute;
    top: 30px;
    right: 20px;
    opacity: .8;
    color: #989494;
    font-size: 14px;
    }
}

@media(max-width: 768px){
    .auth{
min-height: 100vh;

        .auth-col{
            width: 90%;
            margin: 0 auto;
        }
        .auth__logo {
            width: 8rem;
        }
    }
  
}

