//DASHBOARD SIDEBAR
.dashboard {
  background-color: #fafafa;
  overflow: hidden;
  .right-col {
    background: #f2f6ff;
    .mobile-header {
      padding: 2rem 1rem;
      background: white;
    }
  }
  .sidebar {
    background-color: #fff;
    padding: 0;
    position: relative;
    .sidebar__icon {
      color: #c7cfe8;
    }
  }
  .left-wrap {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 17%;
    position: fixed;
    .user-name-container {
      padding-top: 2.5rem;
    }
    .user-name-wrap {
      padding: 0 2.5rem;
      margin-bottom: 1.2rem;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 20px;
      }
      .user__name {
        font-size: 25px;
        color: #091c7a;
        font-weight: 600;
        padding: 0;
        margin: 0;
      }
    }
    .list__link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 2.5rem;
      border-right-color: transparent;
      cursor: pointer;
      font-weight: 500;
      transition: all 1s ease-out;
      border-radius: 0;
      color: #c7cfe8;
      border: none;
      &:hover {
        background-color: #f2f6ff;
        color: #3366fb;
        font-weight: 500;
        padding-left: 2rem;
      }
    }
    .list__link.active {
      background: #f2f6ff;
      color: #091c7a;
      border-right: 4px solid #3366fb;
    }
    .active__icon {
      color: #3366fb;
    }
    .bottom-wrap {
      margin-bottom: 3rem;
    }
  }
  .right-wrap {
    padding: 2rem;
    .top {
      display: flex;
      justify-content: space-between;
    }
    .content {
      min-height: 100vh;
      background-color: #fff;
      filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.02));
      padding: 2rem 0;
      border-radius: 7px;

      .timeline-wrap {
        border-bottom: 1px solid #dee2e6;
      }
      .timeline {
        border: 1px solid #343a40;
        margin: 2rem 23rem;
        min-height: 31rem;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #343a40;
          border-left-color: white;
          border-right-color: white;

          padding: 0.5rem 1rem;
        }
        .left {
          display: flex;
          align-items: center;
          align-content: center;
          .date {
            font-size: 13px;
          }
          .badge {
            background: #3366fb;
            margin-left: 0.8rem;
          }
        }

        .right {
          height: 13px;
          margin-right: 0.6rem;
          display: flex;
          img {
            &:first-child {
              margin-right: 0.5rem;
            }
          }
        }
        .desc {
          p {
            font-size: 13px;
            padding: 0 0.7rem;
            color: #3d3d3d;
            font-weight: 400;
          }
          .read__more {
            color: #3366fb;
          }
        }
      }
    }
  }
}

.select-lg {
  width: 36%;
}
.select-md {
  width: 36%;
}
.select-sm {
  width: 16%;
}

.img__upload {
  border: 1px solid #eaeaea;
  border-radius: 6px;
  width: 8rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img__wrap {
  width: 6rem;
  height: 5rem;
  img {
    height: 100%;
    width: 100%;
  }
}
.db-table {
  overflow: auto;
}

.patternUpload-wrap {
  width: 30%;
}
.upload-pack {
  width: 60%;
}
.mobile-header {
  display: none;
}
.delete__loader {
  height: 1rem;
  width: 1rem;
}
.data__loader {
  height: 2rem;
  width: 2rem;
}

.data__loader {
  border: 0.25em solid #091c7a;
  border-right-color: transparent;
}

@media (max-width: 768px) {
  .dashboard {
    .left-wrap {
      position: inherit;
      width: 100%;
    }
    .right-wrap {
      padding: 1rem;
    }
  }

  #product-info {
    margin: 2rem 1rem;
  }

  .filter__text {
    margin-top: 1rem;
  }

  #product-roles .role-header {
    flex-direction: column;
    .btn-wrap {
      display: flex;
      flex-direction: column;
      .btn:nth-child(1) {
        margin: 1rem 0;
      }
    }
  }

  #product-roles .role-details .btn-wrap {
    display: flex;
    flex-direction: column;
    float: initial;
    .btn:nth-child(1) {
      margin: 1rem 0;
    }
  }

  .team-user-table {
    overflow: scroll;
  }
  .suppliers .title {
    padding-left: 0;
  }
  .mobile-header {
    display: block;
  }
}
