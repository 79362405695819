.settings-sidebar {
  background-color: #091c7a;
  padding: 0;
  color: white;
  position: relative;
  .settings-left-wrap {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 17%;
    position: fixed;
    .mid-wrap,
    .bottom-wrap {
      margin-top: 2rem;
    }
    .title {
      padding: 2.5rem 2rem 0rem;
    }
    .list__link {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 2.5rem;
      border-right-color: transparent;
      cursor: pointer;
      transition: all 1s ease-out;
      border-radius: 0;
      color: #ffffff;
      border: none;
      font-weight: 300;
      &:hover {
        font-weight: 500;
        padding-right: 2rem;
      }
    }
    .list__link.active {
      background: #f2f6ff;
      color: #091c7a;
      border-right: 4px solid #3366fb;
    }
  }
}

#settings-general,
#settings-teams,
#settings-brand,
#settings-status__updates,
#settings-billings,
#settings-invoices,
#settings-info,
#settings-security {
  margin: 0 3rem;
}
#settings-brand {
  .media-box {
    width: 11rem;
    height: 13rem;
  }
}
#settings-status__updates {
  .add__status {
    background: #3366fb;
    padding: 3px 15px;
  }
  .write__status,
  .write__status:focus {
    background: #f7f9fc;
    font-weight: 200;
  }
  .tag {
    display: flex;
    align-items: center;
    i {
      font-size: 17px;
      margin-left: 0.3rem;
    }
  }
}
#settings-billings {
  .sub__text,
  .bill-body {
    padding: 0 2rem;
  }
  .pay__desc {
    color: #8f9bb3;
  }
  .btn__shipping {
    background: #3366fb;
  }
}

#settings-info {
  .user-details,
  .team-table {
    border-radius: 5px;
  }
  .photo {
    height: 6rem;
    border-radius: 10px;
    width:7rem;
  }
  .info-title {
    padding-left: 1rem;
  }
  .info__input{
    border: none;
    &.focus{
      border: 1px solid #dee2e6;
    }
  }
}


.user-tab {
  color: #c7cfe8;
  padding-bottom: 0.6rem;
}
.user-tab.active {
  color: #091c7a;
  font-weight: bold;
  border-bottom: 2px solid #3366fb;
}
@media (max-width: 1200px) {
  #settings-info {
.profile-row{
  flex-direction: column-reverse;
}
  }

}

@media (max-width: 768px) {
  #settings-general,
  #settings-teams,
  #settings-brand {
    margin: 0 1rem;
  }
 
}
