.search,
.search:focus {
  background: #f7f9fc;
  border: none;
  font-size: 13px;
  font-weight: 300;
  color: #8f9bb3;
}
.filter__text {
  color: #8f9bb3;
  font-size: 14px;
  font-weight: 300;
  float: right;
}

.bottom-tab {
  font-size: 12px;
  .nav-link {
    color: #091c7a;
    font-weight: 900;
  }
  i {
    color: #3366fb;
  }
  i.chevron-right {
    color: #091c7a;
  }
}

.form-wrap{
  .info__label {
    color: #091c7a;
    font-size: 14px;
    font-weight: 400;
  }
  .info__input {
    background: #f7f9fc;
    color: #091c7a;
    font-weight: 200;
    font-size: 15px;
    border: 1px solid #e4e9f2;
  }
}

.db-table {
  .table {
    font-size: 14px;
    border-radius: 5px;
    th {
      color: #091c7a;
      padding: 1.5rem;
      border-bottom: 1px solid #dee2e6 !important;
    }
    td {
      padding: 1.5rem;
    }
    i.bi-check-circle {
      color: #3366fb;
      font-size: 18px;
      text-align: center;
    }
    .name, .count, .link {
      color: #091c7a;
      font-weight: 300;
    }
    .link{
        text-decoration: underline;
    }
    .cta {
      color: #3366fb;
      font-weight: 500;
    }
  }
}

.media-box {
    display: flex;
    flex-direction: column;
    .upload-product-text {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f7f9fc;
      color: #9ea8be;
      font-weight: 600;
    }
    .upload-product-text,
    .image {
      height: 24.5rem;
    }
    .dot__icon {
      text-align: right;
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    .tag {
      background: #edf1f7;
      color: #091c7a;
      padding: 0.3rem 1.5rem;
      border-radius: 15px;
      font-weight: 500;
      margin-right: 0.5rem;
      margin-bottom: 0.8rem;
    }
    .tag.active {
      background: #3366fb;
      color: white;
    }
  }
