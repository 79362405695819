#factory-list {
  .team-user-table {
    .table {
      font-size: 14px;
      border-radius: 5px;
      th {
        color: #091c7a;
        padding: 1.5rem;
        border-bottom: 1px solid #dee2e6;
      }
      td {
        padding: 1.5rem;
      }
      i.bi-check-circle {
        color: #3366fb;
        font-size: 18px;
        text-align: center;
      }
      .name {
        color: #091c7a;
        font-weight: 300;
        text-decoration: underline;
      }
      .prd__count {
        color: #091c7a;
        text-align: center;
      }
      .cta {
        color: #3366fb;
        font-weight: 500;
        text-align: right;
      }
    }
  }
}

#factory-list{
  margin: 0 2rem;
}
#factory-banner {
    .hero-img{
        height: 32rem;
    }
}

//PRODUCT STORY

#product-story {
  .contents {
    max-width: 35rem;
    padding: 0 2rem;
    .title {
      font-weight: 600;
      padding: 1.5rem 0;
    }
    .story {
      letter-spacing: 0px;
      color: #282828;
      font-weight: 200;

    }
  }
}
//PRODUCTS CERTS

#products-certs {
  .certifications {
    padding: 0 4rem;
  }
  .image {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    height: 13rem;
    padding: 1rem;
  }
  .title {
    font-weight: 600;
  }
  .details {
    font-size: 14px;
    font-weight: 100;
  }
}

//PRODUCTS MADE
.products-made-wrap {
  padding: 0 4rem;
}

.tabs-wrap {
  // padding: 0 10rem;
  .nav-link {
    text-align: center;
    color: #222b45;
    font-weight: 200;
  }
  .nav-link.active {
    font-weight: 700;
    color: #0095ff;
  }
}
