#db-product {
  .wrap {
    margin: 0 2rem;
  }
  .product-desc {
    margin-top: 2rem;
    background: #f7f9fc;
    .image {
      height: 16rem;
      width: 10rem;
      margin: 1.5rem;
    }
    .contents {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10rem;
      text-align: center;

      h5 {
        font-size: 18px;
        font-weight: 400;
        color: #9ea8be;
      }
      p {
        font-size: 14px;
        font-weight: 200;
        color: #9ea8be;
      }
    }
  }
}

#db-product-list {
  .wrap {
    margin: 0 2rem;
  }
  .upload-product-text, .image {
  height: 25rem;
  }

}

#product-tab {
  margin-top: -2rem;
  .tab-top {
    .nav-link {
      padding: 0.8rem 4.7rem;
      font-size: 14px;
      color: #c7cfe8;
      font-weight: 300;
      border-right: 1px solid #c7cfe8;
      &:hover {
        color: #091c7a;
      }
      &.last {
        border-right: 0;
      }
    }
    .nav-link.active {
      border-bottom: 3px solid #3366fb;
      color: #091c7a;
    }
    .tab {
      border-bottom: 1px solid #dee2e6;
      line-height: 2px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

//PROJECT TIMELINE
#product-timeline {
  .role-switch{
    margin-left: 6rem;
    &:nth-child(1){
      color: #C7CFE8;

    }
  }
  .hero-cta {
    padding: 0.5rem 4rem;

    .reactions {
      margin-right: 0.2rem;
    }
    .nav-link {
      padding: 0 0.6rem;
      border-left: 1px solid #707070a1;
      font-weight: 600;
      color: #0095ff;
    }
  }
  .timeline-wrap {
    border-bottom: 1px solid #dee2e6;
  }
  .f-name {
    padding-right: 0.8rem;
    text-align: center;
  }
  .nav {
    margin-left: 1rem;
  }
  .reaction__emojis {
    i {
      margin-left: 9px;
    }
  }
  .timeline-story {
    overflow: hidden;

    .right-col {
      background: white;
    }
  }
  .timeline {
    width: 24rem;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 0px 0px 5px 5px;
    border: 0;
    .timeline-img {
      height: 25rem;
      img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    .details {
      box-shadow: 0px 3px 6px #00000029;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #dee2e6;
      border-left-color: white;
      border-right-color: white;

      padding: 0.5rem 1rem;
    }
    .left {
      display: flex;
      align-items: center;
      align-content: center;
      .date {
        font-size: 13px;
      }
      .badge {
        background: #4db16c;
        margin-left: 0.8rem;
      }
    }

    .right {
      margin-right: 0.6rem;
      display: flex;
      img {
        &:first-child {
          margin-right: 0.5rem;
        }
      }
    }
    .desc {
      p {
        font-size: 13px;
        padding: 0 0.7rem;
        color: #3d3d3d;
        font-weight: 400;
      }
      .read__more {
        color: #0095ff;
      }
    }
  }
  .timeline-cta{
    margin: 0 auto;
    .btn__add, .btn__edit{
      padding: .7rem 3rem;
      background: #F7F9FC;
      color: #C7CFE8;
  }
  }
  .other-products {
    padding: 2rem 4rem;
    .product__image {
      height: 28rem;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

#product-info {
  margin: 2rem 4.5rem;
  .image {
    height: 20rem;
    img{
      object-fit: cover;
    }
  }
  .bar {
    height: 9rem;
    background: #f2f6ff;
  }
  .title {
    color: #091c7a;
  }
  .download {
    color: #3366fb;
  }
  .info__icon {
    font-size: 13px;
    color: #3366fb;
  }
  .url {
    color: #8f9bb3;
  }
}

#product-media {
  margin: 2rem;
  .image, .upload-product-text{
    height: 18rem;
    img{
      object-fit: cover;
    }
  }
  .upload-media {
    height: 18rem;
  }
  
}

#product-roles,
#product-config {
  .role-header {
    padding: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 15px;
      color: #091c7a;
      font-weight: 300;
      b {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .role-details {
    margin: 2rem;
    .btn-wrap {
      float: right;
      margin-top: 1rem;
    }
   
  }
}

#product-config {
  // .config-right{
  //   padding-left: 3.4rem;
  // }
  .switch__text {
    font-size: 14px;
    margin-top: 3px;
  }
  .config__icon {
    color: #3366fb;
  }
  .config-role {
    padding-left: 3.5rem;
  }
}

#product-header {
  margin-bottom: 0.8rem;
  .desc__text {
    color: #091c7a;
    font-weight: 200;
    font-size: 14px;
  }
  .switch__text {
    color: #c5cee0;
    font-weight: 400;
    font-size: 14px;
  }
}

.suppliers {
  .title {
    font-size: 15px;
    color: #091c7a;
    font-weight: 700;
    padding-left: 1.5rem;
  }
}



@media (max-width: 768px) {
  #product-tab {
    .tab-top .nav-link {
      padding: 0.8rem 1.7rem;
    }
    .tab-top,
    .tab-bottom {
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
  }
  #product-header {
    .product-cta {
      margin-top: 2rem;
      .btn-save__as,
      .custom__switch,
      .btn__publish {
        margin-bottom: 1rem;
      }
    }
  }
  #db-product .product-desc .contents {
    padding: 2rem;
  }



  .product-header-wrap,
  .product-cta {
    flex-direction: column;
  }
  #product-info {
    .col-product__url {
      margin-top: 1.5rem;
    }
    .col-share__msg {
      margin-bottom: 1.5rem;
    }
  }
  #product-config .config-role {
    padding-left: 2rem;
  }
  #product-timeline {
    .hero-cta {
      .cta-wrap {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 1200px) {
  #db-product .product-desc .image {
    height: auto;
    width: auto;
    margin: 1.5rem;
  }
}



