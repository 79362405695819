.modal-content {
  border: none;

  #goal-modal {
    .media__input {
      width: 4rem;
      font-size: 12px;
    }
    .status__label {
      padding-right: 1rem;
    }
  }

  #invite__user-modal {
    .invite__check-label {
      font-size: 16px;
    }
    .invite__user-tags {
      .tag {
        background: #f7f9fc;
        font-size: 16px;
        font-weight: 400;
        border-radius: 17px;
        border: 1px solid #e4e9f2;
        padding: 0.3rem 1.5rem;
        margin-right: 1rem;
      }
    }
  }

  #invite__userOption-modal {
    .invite__option {
      padding: 0 1rem;
    }
    .invite__user-tags {
      .tag {
        background: #f7f9fc;
        font-size: 14px;
        font-weight: 300;
        border-radius: 17px;
        border: 1px solid #e4e9f2;
        padding: 0.1rem 1.4rem;
        margin-right: 1rem;
      }
    }
  }
  .form-wrap {
    padding: 0 1rem;
  }
}
.modal-header,
.modal-footer {
  padding-left: 2rem;
}
.modal-title {
  font-weight: 600;
  font-size: 16px;
}

.close {
  border: none;
  border-radius: 15px;
  width: 2rem;
}
.modal-backdrop {
  background: #091c7a 0% 0% no-repeat padding-box;
  opacity: 0.85 !important;
}

//product add media

.custom-prod-modal,
.custom-create-prod-modal,
.prod-detail-modal,
.add-post-modal {
  .product-add-media-modal-body {
    height: 29rem;
    position: relative;
  }
  .close {
    border: none;
    width: 2rem;
    height: 2rem;
    background: transparent;
    font-size: 2rem;
    color: white;
  }
  .modal-content {
    background-color: transparent;
  }
  .row {
    background: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 5px;
  }
  .info__input {
    font-size: 13px;
  }
  .upload-wrap {
    border: 2px dashed #9ea8be;
    background: #f7f9fc 0% 0% no-repeat padding-box;
  }
  // .upload-wrap:before {
  //     content: "";
  //     position: absolute;
  //     border: 10px dashed #FF0000;
  //     top: -8px;
  //     bottom: -8px;
  //     left: -8px;
  //     right: -8px;
  //   }
  .comment {
    min-height: 16rem;
  }
  .media__icon {
    font-size: 19px;
  }
}

// Product creation modal
.custom-create-prod-modal {
  max-width: 40rem;
  .row {
    background: transparent;
  }
  .upload-wrap {
    border: none;
    border-radius: 5px;
  }
  .media__icon {
    font-size: 17px;
  }
}

//Product detail modal

.prod-detail-modal {
  .prod-details-body {
    height: 30rem;
  }
  .row {
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 5px;
  }
  .img-col {
    padding-left: 0;
    background: white;
  }
  .desc-col {
    background-color: white;
  }
  .sub__name {
    font-weight: 200;
  }
  .desc {
    color: #222b45;
    font-size: 13px;
    font-weight: 400;
  }
  .prod-details {
    padding: 0 1rem;
  }
  .photo {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    img {
      border-radius: 50%;
    }
  }
  .tags {
    max-width: 11rem;
    overflow: scroll;
    flex-wrap: nowrap;
  }
  .tag {
    padding: 0.1rem 1.3rem;
    font-size: 12px;
    margin-bottom: 0;
    background: #3366fb;
    color: white;
  }
}

.edit-page-order {
  .order-wrap {
    height: 4rem;
  }
  .icon-col,
  .details-col {
    background: #f7f9fc;
  }
  .order-icon {
    font-size: 30px;
    color: lightgrey;
    background: #f7f9fc;
  }
  .order-details {
    background-color: #f7f9fc;
  }
}

//ADD POST MODAL

.add-post-modal {
  .widget-sidebar {
    background: #f7f9fc;
  }
  .list__link {
    color: #c7cfe8;
    padding-left: 1rem;
    margin-top: 1rem;
    // margin-bottom: 1rem;
  }
  .list__link.actives {
    border-right: 3px solid #3366fb;
    color: #091c7a;
  }
  .sidebar__icon {
    display: inline-flex;
    font-size: 1.5rem;
    color: #c7cfe8;
  }
  .sidebar__icon.actives {
    color: #3366fb;
  }
  .mid-col {
    height: 28rem;
    overflow: scroll;
  }
  .upload-col {
    height: 20rem;
    margin: 2rem 3rem;
  }
  .image,
  .upload-product-text {
    height: 8rem;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 950px;
  }
}
